var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-layout',{attrs:{"title":_vm.$t('public_referral_title'),"title-target":_vm.chat_title,"user":_vm.currentUser,"gentime":_vm.gentime,"stat-title":_vm.$t('public_referrals_subtitle')}},[_c('div',{staticClass:"w-full flex justify-end"},[_c('a-input-search',{staticClass:"md:w-1/4 w-full mb-4",model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('a-spin',{attrs:{"spinning":_vm.loaded}},[_c('a-table',{key:("table-template-" + _vm.pagination),attrs:{"data-source":_vm.searchResult,"pagination":{
        total: _vm.searchResult.length,
        defaultPageSize: _vm.pagination,
        size: _vm.isMobile() ? 'small' : 'large',
        showSizeChanger: true,
      },"bordered":"","rowKey":function (record) { return record.user_id; },"scroll":{
        x: true
      }}},[_c('a-table-column',{key:"index",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var indexPosition = ref.indexPosition;
return _c('span',{},[_vm._v(" "+_vm._s(indexPosition)+". ")])}}])},[_c('a-icon',{attrs:{"slot":"title","type":"number"},slot:"title"})],1),_c('a-table-column',{key:"name",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var user_name = ref.user_name;
      var user_login = ref.user_login;
      var avatar = ref.avatar;
return _c('user-main-info',{attrs:{"login":user_login,"name":user_name,"avatar":avatar}})}}])},[_c('a-icon',{attrs:{"slot":"title","type":"user"},slot:"title"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }